
:root  {
  --bg:  #161515;
  --text-primary :#3e7697;
  --bg-secondary :#3c4e5f;
  --text-secondary: #e2e5ff;
  --button: #4f9ab1;
  --hover:#06335e;
}

[data-theme="light"] {
    --bg: #faf3dd;
    --bg-secondary: #c8d5b9;
    --text-primary: #7c8094;
    --text-secondary: #7c8b79;
    --button: #8fc0a9;
    --hover: #e9d8a6;
}

[data-theme="dark"] {
  --bg:  #161515;
  --text-primary :#3e7697;
  --bg-secondary :#3c4e5f;
  --text-secondary: #e2e5ff;
  --button: #4f9ab1;
  --hover:#06335e;
}

[data-theme="halloween"] {
  --bg:  #0c0b08;
  --text-primary :#eb5e28;
  --bg-secondary :#42382b;
  --text-secondary: #403d39;
  --button: #94490c;
  --hover:#fffcf2;
}


[data-theme="darkRoom"] {
  --bg:  #b30000;
  --text-primary :#330000;
  --bg-secondary :#800000;
  --text-secondary: #ff0000;
  --button: #660000;
  --hover:#190000;
}

[data-theme="orangeAndTeal"] {
  --bg:  #4f8f91;
  --text-primary :#0d5b62;
  --bg-secondary :#e7582a;
  --text-secondary: #e48365;
  --button: #a4cdd4;
  --hover:#2c5854;
}

[data-theme="purpleMurple"] {
  --bg:  #24346c;
  --text-primary :#5965a4;
  --bg-secondary :#445094;
  --text-secondary: #8ab1cf;
  --button: #b09bc7;
  --hover:#313e58;
}

[data-theme="vaporWave"] {
  --bg:  #2c0452;
  --text-primary :#b252a1;
  --bg-secondary :#66a1d2;
  --text-secondary: #dcd5d5;
  --button: #455f65;
  --hover:#2c3552;
}

[data-theme="valentines"] {
  --bg:  #e57373;
  --text-primary :#ffcdd2;
  --bg-secondary :#c62828;
  --text-secondary: #d32f2f;
  --button: #ef5350;
  --hover:#ff8a80;
}

[data-theme="imBlueDabaDee"] {
  --bg:  #4fc3f7;
  --text-primary :#0277bd;
  --text-secondary: #0091ea;
  --bg-secondary :#e1f5fe;
  --button: #80d8ff;
  --hover:#01579b;
}

[data-theme="ohGodMyEyes"] {
  --bg :#ffed00;
  --text-primary :#008026;
  --bg-secondary:  #e40303;
  --text-secondary: #004dff;
  --button: #ff8c00;
  --hover:#750787;
}

[data-theme="6ShadesOfGray"] {
  --bg :#424242;
  --text-primary :#d5d5d5;
  --bg-secondary:  #bcbcbc;
  --text-secondary: #7c7c7c;
  --button: #999999;
  --hover:#2e2e2e;
}

html{
  overflow-x: hidden;
}

.App {
    width: 100%;
    height: fit-content;
    background: var(--bg);
    color: var(--text-primary);
    transition: all 0.5s ease-in-out;
}

h3 {
    font-size: 50px;
    margin: 5px;
}

h4 {
    font-size: 35px;
    color: var(--text-secondary);
    margin: 5px;
}

h5 {
    font-size: 25px;
    margin: 5px;
}

.resumeProject{
  color: #fefcfb;
}

h6 {
  font-size: 20px;
}

p {
    font-size: 18px;
    color: var(--text-secondary);
    margin: 5px;
}

.pageTitle {
    margin-top: 10vh;
    margin-left: 13vw;
    font-size: 80px;
}
.centerTitle {
    align-self: center;
    font-size: 40px;
}

button{
    appearance: none;
    background-color: var(--text-primary);
    border: 2px solid #1a1a1a;
    border-radius: 10px;
    box-sizing: border-box;
    color: var(--bg-secondary);
    cursor: pointer;
    display: inline-block;;
    font-weight: 600;
    padding: 10px;
    line-height: normal;
    margin: 0;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
}

button:disabled {
  pointer-events: none;
}

button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

button:active {
  box-shadow: none;
  transform: translateY(0);
}


.sorted{
  background-color: var(--hover);
}

.unsorted{
  background-color: var(--text-primary);
}

.comparing{
  background-color: var(--text-secondary);
}

.swapping{
  background-color: var(--button);
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.loadContainer{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 50px;
}
.lds-ellipsis div {
  position: absolute;
  top: 10px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--bg);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 5px 10px 0 10px;
  height: 7px;
  background: var(--text-primary);
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--bg-secondary);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--bg-secondary);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--bg-secondary);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: var(--text-secondary);
}

input[type="range"]::-moz-range-thumb:hover {
  background: var(--text-secondary);
}

input[type="range"]::-ms-thumb:hover {
  background: var(--text-secondary);
}

/* Input Track */
input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
  border-radius: 25px;
}

input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  border-radius: 25px;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  background: transparent;
}