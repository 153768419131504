.navigation{
    z-index: 80;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.navigation ul li{
    list-style: none;
}

.header{
    position: absolute;
    z-index: 100;
    width: 5vw;
    max-width: 110px;
    height:100vh;
    background: var(--bg-secondary);
    display: flex;
    justify-content: center;
    align-content: center;
}


.logo{
    position:absolute;
    top: 5vh;
    width: 3vw;
    max-width: 66px;
}

.menu{
    position: fixed;
    left: 1vw;
    top: 1vh;
    font-size: 200%;
}

.menuButton{
    font-size: clamp(30px, 6vw, 35px);
    margin-bottom: 10px;
    width: 62px;
    height: 62px;
    min-width: 45px;
    min-height: 45px;
    position: relative;
    z-index:100;
    transform: translateX(-4px);
}

.menu li{
    position: absolute;
    transition: all 0.5s ease-in-out;
}

.menuButton svg{
    width: 100%;
    margin: auto;
}

.menu li:nth-child(2){
    top: calc(50px + 2vh);
}

.menu li:nth-child(3){
    top: calc(120px + 2vh);
}

.menu li:nth-child(4){
    top: calc(190px + 2vh);
}

.menu li:nth-child(5){
    top: calc(260px + 2vh);
}

.menu li:nth-child(6){
    top: calc(3300px + 2vh);
}

.icon{
    display: flex;
    flex-direction: row;
    transition: 0.5s;
    margin-bottom: 10px;
    transition: all 0.5s ease-in-out;
}

.icon svg{
    vertical-align: middle;
    transform: translateY(30%);
    width: 100%;
}

.navBar{
    z-index: 100;
    position: absolute;
    bottom: 5vh;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    padding: 0;
}

.navBar li{
    position: relative;
    min-height: 50px;
    margin-bottom: 15px;
    display:inline;
    vertical-align: middle;
}

.navigationItem{
    padding: 10px;
    position: absolute;
    color: var(--bg-secondary);
    background-color: var(--text-primary);
    width: 100px;
    height: 30px;
    font-size: clamp(20px, 0.8vw, 40px);
    line-height: 5vh;
    transition: all 0.75s ease-in-out;
    cursor: pointer;
}

.navigationItem:hover{
    background-color: var(--hover);
    color: var(--text-secondary);
}

.selected{
    background-color: var(--hover);
    color: var(--text-secondary);
    left: -5px;
}
.selected:hover{
    background-color: var(--hover);
    color: var(--text-secondary);
}


.navIcon{
    border-radius: 28px;
    background-color: var(--hover);
    color: var(--button);
    width: 56px;
    height: 56px;
    max-width: 56px;
    display: flex;
    flex-direction: row;
    line-height: 5px;
    overflow: hidden;
    align-items: center;
    transition: max-width 0.5s ease-in-out;
}

.navIcon:hover{
    width: fit-content;
    max-width: 300px;
    padding-right: 15px;
    transition: max-width 0.5s ease-in-out;
}

.navIcon svg{
    width: 40px;
    margin: -15px 10px 0px 10px;
    min-width: 40px;
}